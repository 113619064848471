import Vue from "vue";
import format from "@/helpers/format";
import user from "@/helpers/user";
import toaster from "@/helpers/toaster";
import product from "@/helpers/product";
import console from "@/helpers/console";
import other from "@/helpers/other";
import scroll from "@/helpers/scroll";

const helpers = {
  format: format(),
  user: user(),
  toaster: toaster(),
  product: product(),
  console: console(),
  other: other(),
  scroll: scroll(),
}

Vue.prototype.$helpers = helpers;
