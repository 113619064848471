import format from "./format.js";

export default () => ({
  formatBundlePriceOrigin: (product) => {
    const lpo = Math.round(product.lowest_price_origin);
    const hpo = Math.round(product.highest_price_origin);

    return lpo === hpo ? format().price(lpo || hpo) :`${format().price(lpo)} - ${format().price(hpo)}`;
  },
  formatBundlePrice: (product) => {
    let lp = product.lowest_price;
    let hp = product.highest_price;
    
    if (product.discount_type == 1) {
      lp = lp - product.discount;
      hp = hp - product.discount;
    } else if (product.discount_type == 2) {
      lp = lp * (1 - (product.discount / 100))
      hp = hp * (1 - (product.discount / 100))
    }

    lp = Math.round(lp);
    hp = Math.round(hp);

    return lp === hp ? format().price(lp || hp) :`${format().price(lp)} - ${format().price(hp)}`;
  },
})