export default () => ({
  //* FOR DEVELOPMENT PURPOSE
  //? FormData() log
  formData: (formData) => {
    let index = 1;
    console.log("--------INSPECT FORM DATA--------");
    for (const [key, value] of formData.entries()) {
      console.log(`%cForm Data ke-${index} => %ckey:${key} %cvalue:${(value)}`, "color:green; background:black; font-weight:bold", "color:yellow; background:black", "color:orange; background:black");
      index++;
    }
  }
})
