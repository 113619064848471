import Vue from 'vue'
import App from './Wrapper.vue'
import router from './router'
import store from "./store"
import './registerServiceWorker'
import './api'
import './helpers'

import { BootstrapVue } from 'bootstrap-vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner, faChevronRight, faFilePdf, faClock, faTimes, faPlay, faPause, faCheck } from '@fortawesome/free-solid-svg-icons'; // import asset yang di butuhkan saja
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faSpinner, faChevronRight, faFilePdf, faClock, faTimes, faPlay, faPause, faCheck);

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.min.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import './assets/css/font-icon.css'
import './assets/css/main.css'
import './assets/css/main-custom.css'
import './assets/css/mobile.css'
import './assets/css/content-styles.css'
import './assets/css/font-awesome.css'

import GAuth from 'vue-google-oauth2'
import VueFacebookPixel from 'vue-facebook-pixel';

import Vuelidate from 'vuelidate'

// vue tour for introduction / onboarding website for new user
import VueTour from 'vue-tour'
import 'vue-tour/dist/vue-tour.css'
import Skeleton from 'vue-loading-skeleton'
import ImgSkeleton from './components/mains/ImgSkeleton'

//* Initialize Firebase App
import { initializeApp } from 'firebase/app';
const firebaseConfig = JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG);
const firebaseApp = initializeApp(firebaseConfig);

//* Initialize Firebase Analytics
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
const analytics = getAnalytics(firebaseApp);
Vue.prototype.$firebaseLogEvent = (event_name, custom_parameter) => {
  logEvent(analytics, event_name, custom_parameter);
}
Vue.prototype.$firebaseSetUserProperties = (params) => {
  setUserProperties(analytics, params);
}

Vue.component('font-awesome', FontAwesomeIcon)
Vue.component('fawesome-pro', () => import('./components/mains/FAwesomeMaker'))
Vue.component('img-skeleton', ImgSkeleton)

Vue.use(GAuth, {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: 'profile email',
  prompt: 'select_account'})
Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(VueTour)
Vue.use(Skeleton)
Vue.use(VueFacebookPixel)
Vue.analytics.fbq.init('1689126271393782', { })
Vue.config.productionTip = false

export const eventBus = new Vue(); // * Reference : https://blog.logrocket.com/using-event-bus-in-vue-js-to-pass-data-between-components/

new Vue({
  store,
  router,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
