import store from '../store'
import { eventBus } from '../main'


export default () => ({
  addProductToCartAfterLogin: () => {
    const data = store.getters.getProductAddToCartAfterLogin;
    if (!data) return
    eventBus.$emit('addProductToCartAfterLogin', data)
  },
})